import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'


export const client = new ApolloClient({
  link: new HttpLink({ uri: `${process.env.REACT_APP_DEX_SUBGRAPH}` }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const blockClient = new ApolloClient({
  link: new HttpLink({ uri: `${process.env.REACT_APP_BLOCKS_SUBGRAPH}` }),
  cache: new InMemoryCache(),
})

export const healthClient = new ApolloClient({
  link: new HttpLink({ uri: `${process.env.REACT_APP_HEALTH_SUBGRAPH}` }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})
