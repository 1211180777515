//import { ethers } from "ethers"

const chainId = process.env.REACT_APP_CHAIN_ID || 67676

export const networks: any[] = [
  {
    id: 67676,
    chainId: '0x1085C',
    chainName: 'Naka Chain Testnet',
    rpcUrls: ['https://rpc1.naka.dev/ext/bc/2AUY3K3oT9DSca5bLip82azm5wcbLVVBMPzK4QgdrMYk9CY2kG/rpc'],
    nativeCurrency: {
      name: 'Tether',
      symbol: 'USDT',
      decimals: 18
    },
    blockExplorerUrls: ['https://explorer.naka.dev/']
  }
  // TODO - add mainnet
]

/**
 * The current selected active network
 */
export const getCurrentNetwork = () => {
  // console.log(ethers.utils.hexlify(67676))
  return networks.find((n) => n.id === Number(chainId))
}