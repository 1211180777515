import { getCurrentNetwork } from 'constants/networks'

export const addNakaToMetamask = async () => {
  try {
    const n = getCurrentNetwork()
    window?.ethereum?.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: n?.chainId,
          chainName: n?.chainName,
          rpcUrls: n?.rpcUrls,
          nativeCurrency: n?.nativeCurrency,
          blockExplorerUrls: n?.blockExplorerUrls
        }
      ]
    })
  } catch (error) {
    console.log(error)
  }
}
