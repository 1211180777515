import React from 'react'
import styled from 'styled-components'
import { AutoColumn } from '../../components/@core/Column'
import TopTokenList from '../../components/@info/TokenList'
import { Search } from '../../components/@info/Search'
import { Updater as TokenDataContextUpdater, useAllTokenData } from '../../contexts/TokenData'
import { useTopTokens } from 'hooks/useTopTokens'

const PageWrapper = styled(AutoColumn)`
  margin-top: -70px;
  max-width: 1200px;
  padding: 0 15px;
  width: 100%;
  @media screen and (max-width: 720px) {
    margin-top: 0px;
  }
`

const PageTitle = styled.h1`
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0.67px;
  margin-bottom: 16px;
`

export default function PageTokens() {
  const allTokens = useAllTokenData()
  const topTokens = useTopTokens() // by IoSan

  return (
    <>
      <TokenDataContextUpdater />
      <PageWrapper>
        <PageTitle>Top Tokens</PageTitle>
        <Search />
        <div style={{ height: '17px' }} />
        <TopTokenList tokens={topTokens} itemMax={50} />
      </PageWrapper>
    </>
  )
}
