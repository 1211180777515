export const FACTORY_ADDRESS = `${process.env.REACT_APP_FACTORY_ADDRESS}`

export const BUNDLE_ID = '1'

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  HALF_YEAR: '6 months',
  ALL_TIME: 'All time'
}

// token list urls to fetch tokens from - use for warnings on tokens and pairs
export const SUPPORTED_LIST_URLS__NO_ENS = [
  `${process.env.REACT_APP_TOKENLIST}`
]

export const TOKEN_BLACKLIST = [ // hide from overview list
] 
export const PAIR_BLACKLIST = [] // pair blacklist
export const FEE_WARNING_TOKENS = [] // For tokens that cause erros on fee calculations
export const TRACKED_OVERRIDES_PAIRS = [] // pairs that should be tracked but arent due to lag in subgraph
export const TRACKED_OVERRIDES_TOKENS = [] // tokens that should be tracked but arent due to lag in subgraph all pairs that include token will be tracked

export const UNTRACKED_COPY = 'Derived USD values may be inaccurate without liquid stablecoin or WUSDT pairings.'

// warnings to display if page contains info about blocked token
export const BLOCKED_WARNINGS = {
  // '0xf4eda77f0b455a12f3eb44f8653835f377e36b76':
  //   'TikTok Inc. has asserted this token is violating its trademarks and therefore is not available.',
}