import React, { useRef } from 'react'
import styled from 'styled-components'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
import { Menu } from 'react-feather'
import { NavLink } from 'react-router-dom'

const StyledMenuButton = styled.button`
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 25px;
  border: solid 1px ${({ theme }) => theme.bg4};
  background-color: transparent;
  margin: 0;
  padding: 0;
  
  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }
  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`
const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span`
  min-width: 9rem;
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 90px;
  right: 0rem;
  z-index: 100;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    top: 75px;
  `};
`

const activeClassName = 'ACTIVE'
const MenuItem = styled(NavLink).attrs({activeClassName})`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.text2};
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
  }
  > svg {
    margin-right: 8px;
  }
`


export default function MainMenu() {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.MAIN_MENU)
  const toggle = useToggleModal(ApplicationModal.MAIN_MENU)
  useOnClickOutside(node, open ? toggle : undefined)

  return (
    <StyledMenu ref={node as any}>

      <StyledMenuButton onClick={toggle}>
        <Menu size={'24'} />
      </StyledMenuButton>

      {open && (
        <MenuFlyout>
          <MenuItem to="/swap" onClick={toggle}>
            Swap
          </MenuItem>
          <MenuItem to="/tokens" onClick={toggle}>
            Tokens
          </MenuItem>
          <MenuItem to="/pool" onClick={toggle}>
            Pools
          </MenuItem>
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
