import { client } from 'apollo/client'
import { GetTopTokens } from 'apollo/queries'
import { useEffect, useState } from 'react'

/**
 * useTopTokens
 * @returns Top tokens for the last 24 hours with volumes and price change from the previous day
 * Author: IoSan
 */

export const useTopTokens = () => {
  const [topTokens, setTopTokens] = useState([])

  useEffect(() => {
    let now = new Date()
    now.setHours(0, 0, 0, 0)
    const _24h = now.getTime() / 1000
    now.setDate(now.getDate() - 1)
    const _48h = now.getTime() / 1000

    const controller = new AbortController()
    ;(async () => {
      let results: any = []
      try {
        const _top24h = await client.query({ query: GetTopTokens(_24h) })
        const _top48h = await client.query({ query: GetTopTokens(_48h, _24h) })

        _top24h?.data?.tokenDayDatas?.map((tokenDayData: any) => {
          const _prevTokenDayData = _top48h?.data?.tokenDayDatas.find((t: any) => t.token.id === tokenDayData.token.id)
          let priceChange = 0
          if (_prevTokenDayData) {
            priceChange = ((tokenDayData?.priceUSD - _prevTokenDayData?.priceUSD) / _prevTokenDayData?.priceUSD) * 100
            // console.log('Price Change (24h)', priceChange + '%')
          }
          results.push({
            id: tokenDayData.token.id,
            symbol: tokenDayData.token.symbol,
            name: tokenDayData.token.name,
            totalLiquidityUSD: tokenDayData.totalLiquidityUSD,
            dailyVolumeUSD: tokenDayData.dailyVolumeUSD,
            priceUSD: tokenDayData.priceUSD,
            priceChangeUSD: priceChange
          })
        })
      } catch (error) {
        console.log('useTopTokens', error)
      }
      // Set results
      setTopTokens(results)
    })()
    return () => controller.abort()
  }, [])

  return topTokens
}
