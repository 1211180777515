import React from 'react'
import styled from 'styled-components'
import Settings from '../Settings'
import { RowBetween } from '../@core/Row'
import { TYPE } from '../../theme'

const StyledSwapHeader = styled.div`
  padding: 12px 1rem 0px 1.5rem;
  margin-bottom: 0;
  width: 100%;
  // max-width: 420px;
  color: ${({ theme }) => theme.text2};
`

interface Props {
  type?: string
}

export default function SwapHeader({ type = 'swap' }: Props) {
  return (
    <StyledSwapHeader>
      <RowBetween>
        <div style={{display: 'flex', gap: '25px'}}>
          <TYPE.black fontWeight={500}>{type === 'swap' ? "Swap" : "Trade" }</TYPE.black>
        </div>
        <Settings />
        {/* {type === 'swap' ? <Settings /> : <StyledMenuIcon /> } */}
      </RowBetween>
    </StyledSwapHeader>
  )
}
