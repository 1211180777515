import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
// import { isAddress } from '../../../utils/info/index.js'
import EthereumLogo from '../../../assets/svg/usdt.svg'
import defaultTokenList from '../../../constants/tokenLists/naka-default-tokenlist.json'
import emptyTokenLogo from '../../../assets/images/empty-token.png'

const BAD_IMAGES = {}

const Inline = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`

const Image = styled.img`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
`

const StyledEthereumLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: ${({ size }) => size};
    height: ${({ size }) => size};
  }
`

export default function TokenLogo({ address, header = false, size = '24px', ...rest }) {
  const [error, setError] = useState(false)

  useEffect(() => {
    setError(false)
  }, [address])

  if (error || BAD_IMAGES[address]) {
    return (
      <Inline style={{ width: '24px', height: '24px' }}>
        {/* <span {...rest} alt={''} style={{ fontSize: size }} role="img" aria-label="face">🪙</span> */}
        <Image
          {...rest}
          alt={''}
          src={emptyTokenLogo}
          size={size}
          onError={(event) => {
            BAD_IMAGES[address] = true
            setError(true)
            event.preventDefault()
          }}
        />
      </Inline>

    )
  }

  if (address?.toLowerCase() === `${process.env.REACT_APP_WUSDT_ADDRESS}`.toLowerCase()) {
    return (
      <StyledEthereumLogo size={size} {...rest}>
        <img
          src={EthereumLogo}
          style={{
            boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.075)',
            borderRadius: '24px',
          }}
          alt=""
        />
      </StyledEthereumLogo>
    )
  }

  let path = ''
  if (defaultTokenList.tokens) {
    const token = defaultTokenList.tokens.find((t) => t?.address.toLowerCase() === address?.toLowerCase())
    if (token) {
      path = token.logoURI
    }
  } else {
    // path = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${isAddress(address)}/logo.png`
    path = emptyTokenLogo
  }

  return (
    <Inline>
      <Image
        {...rest}
        alt={''}
        src={path}
        size={size}
        onError={(event) => {
          BAD_IMAGES[address] = true
          setError(true)
          event.preventDefault()
        }}
      />
    </Inline>
  )
}
